import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/auth/Login";
import Home from "./pages/Home";
import { useSelector } from 'react-redux'

const App = () => {
  const { user, socialLogin } = useSelector(state => state.auth);
  useEffect(()=>{
    console.log(socialLogin)
  },[user, socialLogin])

  return (
    <div>
      <Routes>
      
        <Route path="/"  element={socialLogin || user ? <Home /> : <Navigate to='/login' />} />
        <Route path="/login" element={!socialLogin ? <Login /> : <Navigate to='/' />} />
        <Route path="/login" element={!user ? <Login /> : <Navigate to='/' />} />
      </Routes>
    </div>
  );
};

export default App;
import axios from "axios";

axios.defaults.baseURL = "https://sr-fms-api.herokuapp.com/";

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const httpRequest = (req) => {
  return new Promise(async (resolve, reject) => {
    try {
      const request = await axios(req);
      resolve(request.data);
    } catch (e) {
      reject(e?.response?.data || {});
    }
  });
};

export default httpRequest;
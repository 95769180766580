import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Navbar from '../components/Navbar'
import ListItem from '../components/ListItem'
import { useSelector } from 'react-redux'


const Home = () => {
  const { user } = useSelector(state => state.auth);
  const [state, setstate] = useState({})
  useEffect(()=>{
    setstate(user)
  },[state])
  
  const data = {
    ready:[
      {
        title:'FMS',
        desc:'Fleet Management Solution',
        link:`https://saferoad-fms-web-stage.vercel.app/auth/signin?${state?.fms_new_token !== undefined ? 'nw=' + state?.fms_new_token + '&at=' + state?.access_token : ''}`,
        icon:'saferoad-logo.svg',
        isReady:true
      },
      {
        title:'CCM',
        desc:'Cold Chain Management',
        link:`https://ccm.saferoad.com.sa/?q=${user?.ccm_token !== undefined ? user?.ccm_token : ''}`,
        icon:'coldchain-logo.svg',
        isReady:true
      },
      {
        title:'SHARE-X',
        desc:'Car Sharing Solution',
        link:`https://saferoad-sharex-web.vercel.app/auth/signin${user?.sharexToken !== undefined ? '?q=' + user?.sharexToken : ''}`,
         icon:'shareX-logo.svg',
        isReady:true
      },
    ],
    notReady:[
      {
        title:'RMS',
        desc:'Rental Management Solution',
        link:'/#',
         icon:'saferoad-logo.svg',
        isReady:false
      },
      {
        title:'TMS',
        desc:'Truck  Management Solution',
        link:'/#',
         icon:'saferoad-logo.svg',
        isReady:false
      },
      {
        title:'FMS',
        desc:'Fleet Management Solution',
        link:'https://track.v6.saferoad.net',
         icon:'saferoad-logo.svg',
        isReady:false
      },
      {
        title:'DEP',
        desc:'Driving Enhancement Program',
        link:'/#',
         icon:'saferoad-logo.svg',
        isReady:false
      }
    ]
  }
  
    
  return (
    <div>
      <Navbar/>

      <div className='home'>
          <Container>
          <h2>Our solutions</h2>
          <Row>
          {
            data?.ready?.map(item => {
              return(
                <Col md='6' key={item.title}>
                  <ListItem {...item} />
                </Col>
              )
            })
          }
          </Row>

          <h2>Coming Soon</h2>
          <Row className='opacity-50'>
          {
            data?.notReady?.map(item => {
              return(
                <Col md='6' key={item.title}>
                  <ListItem {...item} />
                </Col>
              )
            })
          }
          </Row>

          </Container>
      </div>
    </div>
  )
}

export default Home
import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { encryptName } from '../../helpers/encryptions'

export const fetchUserSlice = createSlice({
  name: 'auth',
  initialState: {
    user: (Cookies.get(encryptName("getUser")) && JSON.parse(Cookies.get(encryptName("getUser"))))  
    || (sessionStorage.getItem(encryptName("getUser")) && JSON.parse(sessionStorage.getItem(encryptName("getUser")))) 
    || (localStorage.getItem(encryptName("getUser")) && JSON.parse(localStorage.getItem(encryptName("getUser")))) 
    || null,
    socialLogin:(Cookies.get(encryptName("getSocialUser")) && JSON.parse(Cookies.get(encryptName("getSocialUser")))) || null
  },
  reducers: {
    getUser: (state, action) => {
      state.user = action.payload
    },
    getSocialUser:(state, action) => {
      state.socialLogin = action.payload
    }
  }
})

export const { getUser,getSocialUser } = fetchUserSlice.actions
export default fetchUserSlice.reducer
import React from 'react';
import { Card } from 'react-bootstrap';


const ListItem = ({title,desc,link, icon, isReady}) => {
  return (
    <a href={link} target='_blank' rel="noreferrer" onClick={e => !isReady && e.preventDefault()} style={{cursor: !isReady && 'url("/cursor.png") 4 12, auto'}} >
    <Card className='mb-4 sf-list-item border-0'>
      <Card.Body className='card-container'>
          <div className='icon'>
            <img src={icon} alt={title} />
          </div>
          <div className='text'>
            <h2>{title}</h2>
            <p>{desc}</p>
          </div>
      </Card.Body>
    </Card>
    </a>
  )
}

export default ListItem